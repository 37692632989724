
define('savedBuildInitializer', ['viewData', 'jquery', 'templateForm', 'templateData', 'perspectiveHotspotPreview', 'review', 'loadedTemplates'],
	function (viewData, $, templateForm, templateData, perspectiveHotspotPreview, review, loadedTemplates) {
		'use strict';

		var zoomViewSelector = '.preview-container .zoom-view';
		var perspectiveHotspotPreviewTimer = 0;
		var reviewTimer = 0;

		var initializeBuild = function () {
			var savedBuild = viewData.SavedBuild;

			if (!savedBuild)
				return;

			// initialize the hotspots in the saved build
			savedBuild.Hotspots.forEach(function (hotspot) {
				initializeHotspot(hotspot);
			});
		};

		var initializeHotspot = function (hotspot) {


			// get the hotspot element
			var $hotspot = $('.hotspot-view[data-id=' + hotspot.ID + ']');

			// get the template group
			var $templateGroup = $hotspot.find('.template-group[data-id=' + hotspot.TemplateGroupID + ']');

			// mark the hotspot applied
			$templateGroup.parents('.hotspot-view').addClass('applied');

			// activate the appropriate tab
			$templateGroup.parents('.menu-tab').addClass('open applied');

			// activate the appropriate template group
			$templateGroup.addClass('open').addClass('open applied');

			// get the template form container
			var $templateFormContainer = $templateGroup.find('.template-form');

			// load the templates
			templateData.getTemplate(hotspot.Template.ID).done(function (template) {

				// load the template into the library
				loadedTemplates.loadTemplate(template);

				// initialize template form
				templateForm.initializeForm($templateFormContainer, template);

				// remove the overlay from the previews
				var $zoomView = $(zoomViewSelector);
				$zoomView.removeClass('customizing').addClass('customizing');

				// initialize the saved build values
				applySavedBuildValuesToForm($templateFormContainer, hotspot);

				// request the previews for the hotspots in the perspective preview
				showPerspectiveHotspotPreviews();

				// initialize the review section
				refreshReviewSection();
				// if there is at least 1 hotspot applied than show the review content instead of the project help text
				if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
					$('.menu .project-help-text').addClass('hide');
					$('.menu .review-container').removeClass('hide');
				}

				// change button copy
				// $('.review-content .add-to-cart').html('SAVE TO CART');


				//if (viewData.Automated) {
				//	setTimeout(
				//		function () {
				//			$('.add-to-cart').click();
				//		}, 5000);

				//}
			});
		};

		var applySavedBuildValuesToForm = function ($formContainer, hotspot) {
			hotspot.Fields.forEach(function (field) {
				var $field = $formContainer.find('.field[data-id=' + field.UUID + ']');
				$field.find('.field-option-select').val(field.ActiveOptionUUID).change();
				var $fieldOption = $field.find('.field-options .field-option[data-id=' + field.ActiveOptionUUID + ']');
				if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) != -1) {	// text field type
					$fieldOption.find('.text-format-select').val(field.SelectedOption.ActiveFormatUUID).change();
					$fieldOption.find('.Text-field .customizable-item-wrapper').addClass('active');
					$fieldOption.find('.Text-field input.field-option-text-input').val(field.Text).change();
					$fieldOption.find('.Color-field input').val(field.ColorID).change();
					$fieldOption.find('.OutlineColor-field input').val(field.OutlineColorID).change();
					$fieldOption.find('.ShadowColor-field input').val(field.ShadowColorID).change();
				}
				else if (field.Type == 1) {	// image field type
					$field.addClass('saved-build-first-view');
					$fieldOption.find('.Image-field input').val(field.ImageID).change();
				}
				else if (field.Type === 11) {// custom select option
					field.SelectedOptions.forEach(function (selectedCustomOption) {
						$fieldOption.find('input[data-sku=' + selectedCustomOption.Sku + ']').prop('checked', true);
					});
				}
				else if (field.Type === 12) {// custom image         
					$fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(field.CustomImage.CloudinaryId);
					$fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(field.CustomImage.Version);
				}
				else if (field.Type === 15) {// // salesperson/NS sku   
					// sku 1
					$fieldOption.find('.NS-Sku1-Description').html(field.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Description-Hidden').html(field.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Price').val(field.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Price-Hidden').val(field.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Cost').val(field.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Cost-Hidden').val(field.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Hidden').val(field.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.NS-Sku1').val(field.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');

					// sku 2
					$fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
					if (field.NetSuiteSalesPersonData.Sku2 != null && field.NetSuiteSalesPersonData.Sku2 != '') {
						$fieldOption.find('#NetSuite-Sku2-Option').click();
						$fieldOption.find('.NS-Sku2-Description').html(field.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Description-Hidden').html(field.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Price').val(field.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Price-Hidden').val(field.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Cost').val(field.NetSuiteSalesPersonData.Sku2Cost);
						$fieldOption.find('.NS-Sku2-Cost-Hidden').val(field.NetSuiteSalesPersonData.Sku2Cost);
						$fieldOption.find('.NS-Sku2-Hidden').val(field.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.NS-Sku2').val(field.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
					} else {
						$fieldOption.find('.NS-Sku2-Cost').val('');						
                    }
				}
			});
			// Notes field
			if (hotspot.Notes != undefined && hotspot.Notes != null && hotspot.Notes != '') {
				$formContainer.find('.field-notes .notes-container textarea').text(hotspot.Notes);
			}
		};

		var showPerspectiveHotspotPreviews = function () {
			clearTimeout(perspectiveHotspotPreviewTimer);
			perspectiveHotspotPreviewTimer = setTimeout(function () {
				perspectiveHotspotPreview.showPerspectiveHotspotPreviews();
			}, 700);
		};

		var refreshReviewSection = function () {
			clearTimeout(reviewTimer);
			reviewTimer = setTimeout(function () {
				review.refreshReviewUI();
			}, 700);
		};

		return {
			initializeBuild: initializeBuild
		};
	});
require(['perspectiveListing', 'perspectivePreview', 'hotspotPreview', 'menu', 'templateForm', 'review', 'templateGroups', 'loadedTemplates', 'perspectiveHotspotPreview', 'imageSelectorFieldControl', 'addToCart', 'savedBuildInitializer', 'applyHotspot', 'updateUI', 'overlay'], function
    (perspectiveListing, perspectivePreview, hotspotPreview, menu, templateForm, review, templateGroups, loadedTemplates, perspectiveHotspotPreview, imageSelectorFieldControl, addToCart, savedBuildInitializer, applyHotspot, updateUI, overlay) {
	'use strict';

    var init = function () {
        // add overlay to prevent unwanted clicks
        overlay.addOverlay();
        setTimeout(function () {
            overlay.removeOverlay();
         }, 2000);


		// run init functions for relevant modules
		review.init();
		perspectivePreview.init();
		templateGroups.init();
		menu.init(); 
		hotspotPreview.init();
		templateForm.init();
		loadedTemplates.init();
		perspectiveHotspotPreview.init();
		imageSelectorFieldControl.init();
		addToCart.init();
		applyHotspot.init();

		// run initializeUI functions for relevant modules
		perspectiveListing.initializeUI();
		perspectivePreview.initializeUI();

		// initialize the saved build
		savedBuildInitializer.initializeBuild();

        review.refreshReviewUI();

        updateUI.init();

        
	};

	init();
});